<template>
  <v-sheet elevation="2" class="pa-5" overflow="scroll">
    <v-container fluid>
      <v-row>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5">
              <h1>{{ value.transaction_number }}</h1>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-col> </v-col>
      </v-row>
      <!--Transaction details-->
      <v-row>
        <v-col sm="4" md="4">
          <h4>Transaction Type</h4>
        </v-col>
        <v-col sm="4" md="4">
          <h4>Date</h4>
        </v-col>
        <v-col sm="4" md="4">
          <h4>Amount</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="4" md="4">
          {{ value.transaction_type }}
        </v-col>
        <v-col sm="4" md="4">
          {{ value.transaction_date }}
        </v-col>
        <v-col sm="4" md="4">
          {{ formatAsCurrency("R", Math.abs(value.transaction_amount)) }}
        </v-col>
      </v-row>
      <!--Divider-->
      <v-row class="my-3">
        <v-col>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <!--Bank account details-->
      <v-row>
        <v-col sm="6" md="6">
          <h4>Bank Name</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="6" md="6">
          {{ value.bank_account_name }}
        </v-col>
      </v-row>
      <!--Divider-->
      <v-row class="my-3">
        <v-col>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <!--Allocation Details-->
      <v-row>
        <v-col sm="6" md="6">
          <h4>Allocated</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          sm="6"
          md="6"
          v-if="value.transaction_allocation_status === 'General Journal Entry'"
        >
          <router-link to="/">{{
            value.transaction_allocation_status
          }}</router-link>
        </v-col>
        <v-col sm="6" md="6" v-else>
          {{ value.transaction_allocation_status }}
        </v-col>
      </v-row>
      <!--Divider-->
      <v-row class="my-3">
        <v-col>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <!--Source Details-->
      <v-row>
        <v-col sm="4" md="4">
          <h4>Input Source</h4>
        </v-col>
        <v-col sm="4" md="4">
          <h4>Created By</h4>
        </v-col>
        <v-col sm="4" md="4">
          <h4>Date Added</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="4" md="4">
          {{ value.transaction_input_source }}
        </v-col>
        <v-col sm="4" md="4">
          {{ value.transaction_created_by_name }}
        </v-col>
        <v-col sm="4" md="4">
          {{ value.transaction_date_created }}
        </v-col>
      </v-row>
      <!--Attachments-->
      <div v-if="value.attachments.length > 0">
        <!--Divider-->
        <v-row class="my-3">
          <v-col>
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12" md="12">
            <h4><v-icon small left>mdi-attachment</v-icon> Attachments</h4>
          </v-col>
        </v-row>
        <v-row v-for="item in value.attachments" :key="item.src">
          <v-col sm="12" md="12">
            <a :href="item.src" target="blank">
              {{ item.file_name }}
            </a>
          </v-col>
        </v-row>
      </div>
      <!--Divider-->
      <v-row class="my-3">
        <v-col>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="printTransaction(value)">
              <v-icon left>mdi-printer</v-icon> Print Transaction
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>
<script>
import mixinCompanyProfile from "../../../globalActions/mixin_CompanyProfile";
import { formatAsCurrency } from "../../../composables/external";
import jsPDF from "jspdf";
import "jspdf-autotable";
export default {
  name: "BankSatementsViewTransactionModal",
  data() {
    return {};
  },
  props: ["value"],
  mixins: [mixinCompanyProfile],
  computed: {},
  methods: {
    formatAsCurrency,
    //
    printTransaction(value) {
      const doc = new jsPDF({
        unit: "mm",
        format: "a4",
        compress: true,
      });

      //Title
      doc.setTextColor("black");
      doc.setFontSize(18);
      doc.setFont("helvetica", "bold");
      doc.text(`Bank Transaction ${value.transaction_number}`, 16, 20, "left");

      doc.autoTable({
        margin: { top: 30 },
        theme: "plain",
        head: [["Type", "Transaction Date", "Amount"]],
        body: [
          [
            value.transaction_type,
            value.transaction_date,
            formatAsCurrency(value.transaction_amount),
          ],
        ],
      });

      doc.autoTable({
        margin: { top: 30 },
        theme: "plain",
        head: [["Bank Name"]],
        body: [[value.bank_account_name]],
      });

      doc.autoTable({
        margin: { top: 30 },
        theme: "plain",
        head: [["Allocated"]],
        body: [[value.transaction_allocation_status]],
      });

      doc.autoTable({
        margin: { top: 30 },
        theme: "plain",
        head: [["Input Source", "Created By", "Date Added"]],
        body: [
          [
            value.transaction_input_source,
            value.transaction_created_by_name,
            value.transaction_date_created,
          ],
        ],
      });

      //PDF FOOTER

      //Company details
      doc.setFontSize(9);
      doc.setFont("helvetica", "normal");
      doc.text(
        this.company_legal_name +
          " " +
          this.company_trading_as +
          " " +
          "Reg No. " +
          this.company_reg_number,
        105,
        266,
        "center"
      );
      doc.text(
        this.company_physical_address_line_1 +
          ", " +
          this.company_physical_address_line_2 +
          ", " +
          this.company_physical_address_city +
          ", " +
          this.company_physical_address_zip,
        105,
        272,
        "center"
      );

      //Page count
      var pageCount = doc.internal.getNumberOfPages();
      var i = 0;
      for (i = 0; i < pageCount; i++) {
        doc.setPage(i);
        doc.text(
          186,
          282,
          doc.internal.getCurrentPageInfo().pageNumber + " of " + pageCount
        );
      }
      //Save PDF
      //doc.save(`${value.transaction_number}.pdf`);
      //View PDF in new browser window (Testing only)
      doc.output("dataurlnewwindow");
    },
  },
};
</script>
